
import api from "../../api"
export const state = {

    companyTransactionsList: [],
    companyTransactionsData: {},
    errorMsg: null,
    successMsg: null,
} 
export const mutations = {
 
    SET_COMPANY_TRANSACTIONS_LIST(state, companyTransactionsList) {
        state.companyTransactionsList = companyTransactionsList
    },
    SET_COMPANY_TRANSACTIONS_DATA(state, companyTransactionsData){
        state.companyTransactionsData = companyTransactionsData
    },
 
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },
  
  
}

export const getters = {
   getcompanyTransactionsList: state => state.companyTransactionsList,
   companyTransactionsData: state => state.companyTransactionsData,
   errorMsg: state => state.errorMsg,
   successMsg: state => state.successMsg,
}

export const actions = {
    async fetchCompanyTransactionsList({commit}, companyId, page = 1){
        await api({requiresAuth: true}).get(`/companies/${companyId}/transactions?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANY_TRANSACTIONS_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },

    async getCompanyTransactionsData({commit}, {companyId: companyId, page: page = 1}){
        
        await api({requiresAuth: true}).get(`/companies/${companyId}/transactions?page=${page}`)
        .then((result) => {
            const {data}  = result;
            commit('SET_COMPANY_TRANSACTIONS_DATA', data)
        })
        .catch(error => {
            console.error(error)
            
        })
    }
}