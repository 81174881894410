// import axios from "axios";
import api from "../../api"
export const state = {
    areLeadsLoading: false,
    leadsList: [],
    leadsData: {},
    errorMsg: null,
    successMsg: null,

    isSearchingLeads: false, 
    searchLeadsData: {},
}

export const mutations = {
    SET_LOADING(state, areLeadsLoading){
        state.areLeadsLoading = areLeadsLoading
    },
    SET_LEADS_LIST(state, leadsList) {
        state.leadsList = leadsList
    },
    SET_LEADS_DATA(state, leadsData){
        state.leadsData = leadsData
    },
    UPDATE_LEAD(state, updatedLead){
        state.leadsList = state.leadsList.map(lead => 
            lead.id === updatedLead.id ? updatedLead : lead
        )
    },
    DELETE_LEAD(state, leadId){
        state.leadsList = state.leadsList.filter(lead => lead.id !== leadId)
    },
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },
    SET_IS_SEARCHING(state, isSearchingLeads) {
        state.isSearchingLeads = isSearchingLeads;
    },
   
}

export const getters = {
   getleadsList: state => state.leadsList,
   leadsData: state => state.leadsData,
   areLeadsLoading: state => state.areLeadsLoading,
   errorMsg: state => state.errorMsg,
   successMsg: state => state.successMsg,
   isSearchingLeads: state => state.isSearchingLeads,
   searchLeadsData: state => state.searchLeadsData,
}

export const actions = {
    async fetchLeadsList({commit}, page = 1){
        await api({requiresAuth: true}).get(`/leads?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_LEADS_LIST', data)
            })
            .catch(error => {
                console.error(error)
                
            })
    },

   
    async updateLead({commit}, updateLead){
        await api({requiresAuth: true}).patch(`/leads/${updateLead.id}`, updateLead)
        .then(() => {
            commit('UPDATE_LEAD', updateLead);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Lead updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async deleteLead({commit}, leadId){
        await api({ requiresAuth: true }).delete(`/leads/${leadId}`)
        .then(() => {
            commit('DELETE_LEAD', leadId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Kunden deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async getLeadsData({commit}, page = 1){
        
        await api({requiresAuth: true}).get(`/leads?page=${page}`)
        .then((result) => {
            const {data}  = result;
            commit('SET_LEADS_DATA', data)
        })
        .catch(error => {
            console.error(error)
            
        })
        
    },
    async getSearchLeadsData({ commit }, { page, searchDataObject }) {
        // commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

       // commit('SET_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/leads/search?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_LEADS_DATA', data);
            commit('SET_ERROR_MSG', null); 
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },
}