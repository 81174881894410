
import api from "../../api"
export const state = {

    offersByStatusList: [],
    offersByStatusData: {},
    isSearchingOffersByStatus: false, 
    searchOffersByStatusData: {},
}

export const mutations = {

    SET_OFFERS_LIST(state, offersByStatusList) {
        state.offersByStatusList = offersByStatusList
    },
    SET_OFFERS_DATA(state, offersByStatusData) {
        state.offersByStatusData = offersByStatusData
    },
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },
    SET_IS_SEARCHING(state, isSearchingOffersByStatus) {
        state.isSearchingOffersByStatus = isSearchingOffersByStatus;
    },
    
}

export const getters = {
    getoffersByStatusList: state => state.offersByStatusList,
    offersByStatusData: state => state.offersByStatusData,
    errorMsg: state => state.errorMsg,
    successMsg: state => state.successMsg,
    isSearchingOffersByStatus: state => state.isSearchingOffersByStatus,
    searchOffersByStatusData: state => state.searchOffersByStatusData,
}

export const actions = {
    async getAllOffersData({ commit }) {
        await api({ requiresAuth: true }).get(`/offers`)
            .then((result) => {
                const { data } = result;
                commit('SET_OFFERS_DATA', data)
            })
            .catch(error => {
                console.error(error)

            })
    },
    async getOffersByStatusData({ commit }, { statusId,  page, perPage }) {
        var url = `/offers?page=${page}&perPage=${perPage}`;
        if(statusId != null){
             url =`/offers?status=${statusId}&page=${page}&perPage=${perPage}`;
        }
        commit('SET_IS_SEARCHING', false);
        await api({ requiresAuth: true }).get(url)
            .then((result) => {
                const { data } = result;
                commit('SET_OFFERS_DATA', data)
            })
            .catch(error => {
                console.error(error)

            })
    },

    async getSearchOffersByStatusData({ commit }, { statusId,  page, perPage, searchDataObject }) {
        // commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        // commit('SET_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/offers/search?status=${statusId}&page=${page}&perPage=${perPage}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_OFFERS_DATA', data);
            commit('SET_ERROR_MSG', null); 
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },
    
}