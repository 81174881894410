
import api from "../../api"
export const state = {

    companyTimeoutsList: [],
    companyTimeoutsData: {},
    errorMsg: null,
    successMsg: null,
} 
export const mutations = {
 
    SET_COMPANY_TIMEOUTS_LIST(state, companyTimeoutsList) {
        state.companyTimeoutsList = companyTimeoutsList
    },
    SET_COMPANY_TIMEOUTS_DATA(state, companyTimeoutsData){
        state.companyTimeoutsData = companyTimeoutsData
    },
 
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },
  
  
}

export const getters = {
   getcompanyTimeoutsList: state => state.companyTimeoutsList,
   companyTimeoutsData: state => state.companyTimeoutsData,
   errorMsg: state => state.errorMsg,
   successMsg: state => state.successMsg,
}

export const actions = {
    async fetchCompanyTimeoutsList({commit}, companyId, page = 1){
        await api({requiresAuth: true}).get(`/companies/${companyId}/tinmeouts?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANY_TIMEOUTS_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },

    async getCompanyTimeoutsData({commit}, {companyId: companyId, page: page = 1}){
        
        await api({requiresAuth: true}).get(`/companies/${companyId}/timeouts?page=${page}`)
        .then((result) => {
            const {data}  = result;

        
            commit('SET_COMPANY_TIMEOUTS_DATA', data)
        })
        .catch(error => {
            console.error(error)
            
        })
    }
}