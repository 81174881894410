
import api from "../../api"
export const state = {
    companiesList: [],
    companiesData: {},
    newCompany: null,
    errorMsg: null,
    successMsg: null,
} 
export const mutations = {
    SET_COMPANIES_LIST(state, companiesList) {
        state.companiesList = companiesList
    },
    SET_COMPANIES_DATA(state, companiesData){
        state.companiesData = companiesData
    },
    UPDATE_COMPANY(state, updatedCompany){
        state.companiesList = state.companiesList.map(company => 
            company.id === updatedCompany.id ? updatedCompany : company
        )
    },
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },
    ADD_COMPANY(state, newCompany){
        state.newCompany = newCompany
    },
    DELETE_COMPANY(state, companyId){
        state.companiesList = state.companiesList.filter(company => company.id !== companyId)
    },
}
export const getters = {
   getcompaniesList: state => state.companiesList,
   companiesData: state => state.companiesData,
   errorMsg: state => state.errorMsg,
   successMsg: state => state.successMsg,
}
export const actions = {
    async fetchCompaniesList({commit}, page = 1){
        await api({requiresAuth: true}).get(`/companies?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANIES_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },
    async getCompaniesData({commit}, page = 1){
        await api({requiresAuth: true}).get(`/companies?page=${page}`)
        .then((result) => {
            const {data}  = result;
            commit('SET_COMPANIES_DATA', data)
        })
        .catch(error => {
            console.error(error)  
        })
    },
    async updateCompany({commit}, updateCompany){
        await api({requiresAuth: true}).patch(`/companies/${updateCompany.id}`, updateCompany)
        .then(() => {
            commit('UPDATE_COMPANY', updateCompany);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Company updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async addCompany({commit}, newCompany){
        try {
            const response =   await api({requiresAuth: true}).post(`/companies`, newCompany);
            const { data } = response;
    
            commit('ADD_COMPANY', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
            const theId = data.data.id;
            return theId;
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },
    async deleteCompany({commit}, companyId){
        await api({ requiresAuth: true }).delete(`/companies/${companyId}`)
        .then(() => {
            commit('DELETE_COMPANY', companyId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Company deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
}