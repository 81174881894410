import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "Dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/Dashboard.vue"),
  },
  {
    path: "/offers",
    name: " Offer Status Leads",
    meta: {
      title: " Offer Status Leads",
      authRequired: true,
    },
    component: () => import("../views/offer/Offer.vue"),
  },
  {
    path: "/customers",
    name: "leads",
    meta: {
      title: "Customers",
      authRequired: true,
    },
    component: () => import("../views/leads/Leads.vue"),
  },
  {
    path: "/customers/:id",
    name: "Leads",
    meta: {
      title: "Edit Customer",
    },
    component: () => import("../views/leads/EditLead.vue"),
  },
  {
    path: "/company",
    name: "company",
    meta: {
      title: "Company",
      authRequired: true,
    },
    component: () => import("../views/company/Company.vue"),
  },
  {
    path: "/company/:id",
    name: "Company",
    meta: {
      title: "Company",
      authRequired: true,
    },
    component: () => import("../views/company/EditCompany.vue"),
  },
  {
    path: "/report",
    name: "Report",
    meta: {
      title: "Report",
      authRequired: true,
    },
    component: () => import("../views/report/Report.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  }
];