
import api from "../../api"
export const state = {

    companyOffersList: [],
    companyOffersData: {},
    errorMsg: null,
    successMsg: null,
} 
export const mutations = {
 
    SET_COMPANY_OFFERS_LIST(state, companyOffersList) {
        state.companyOffersList = companyOffersList
    },
    SET_COMPANY_OFFERS_DATA(state, companyOffersData){
        state.companyOffersData = companyOffersData
    },
 
    SET_ERROR_MSG(state, errorMsg){
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg){
        state.successMsg = successMsg
    },

}

export const getters = {
   getcompanyOffersList: state => state.companyOffersList,
   companyOffersData: state => state.companyOffersData,
   errorMsg: state => state.errorMsg,
   successMsg: state => state.successMsg,
}

export const actions = {
    async fetchCompanyOffersList({commit}, companyId,  page = 1){
        await api({requiresAuth: true}).get(`/companies/${companyId}/offers?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_COMPANY_OFFERS_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },

    async getCompanyOffersData({commit}, {companyId: companyId, page: page = 1}){
        try {
            const result = await api({ requiresAuth: true }).get(`/companies/${companyId}/offers?page=${page}`);
            const { data } = result;
            commit('SET_COMPANY_OFFERS_DATA', data);
          } catch (error) {
            console.error(error);
          }
  
    },
    
    async getCompanyOffersByStatusOrderData({commit}, {companyId: companyId, page: page = 1 , sortDirection : sortDirection = 'asc '  }){
        
        try {
            const result = await api({ requiresAuth: true }).get(`/companies/${companyId}/offers?page=${page}&orderBy=status&sort=${sortDirection}`);
            const { data } = result;
            commit('SET_COMPANY_OFFERS_DATA', data);
          } catch (error) {
            console.error(error);
          }
    },
    async getCompanyOffersByDateOrderData({commit}, {companyId: companyId, page: page = 1 , sortDirection : sortDirection = 'asc '  }){
        
        try {
            const result = await api({ requiresAuth: true }).get(`/companies/${companyId}/offers?page=${page}&orderBy=date&sort=${sortDirection}`);
            const { data } = result;
            commit('SET_COMPANY_OFFERS_DATA', data);
          } catch (error) {
            console.error(error);
          }
    }
}